import { PatientActivityLog } from '../../api';
import { styled } from 'styled-components';
import { Tile } from '../../components/Tile';
import { Heading } from '../../components/Heading.tsx';

const ActivityLogTile = styled(Tile)`
  margin: 40px 0 0 40px;
  display: flex;
  max-height: 100%;
`;

const ActivityLogContainer = styled.div`
  margin: 16px 0;
`;

const ActivityLogValue = styled.p`
  margin: 0 0 0 18px;
  font-size: ${props => props.theme.fontSizes.sm};
`;

export const ActivityLogContent = (activityLogs: PatientActivityLog[]) => {
  return (
    <ActivityLogTile title={'Recent Activity'}>
      {activityLogs.map((activityLog, index) => (
        <ActivityLogContainer key={index}>
          <Heading size={'sm'} key={index}>
            Log ID: {activityLog.id}
            <br />
          </Heading>
          {Object.entries(activityLog).map(([key, value]) => (
            <ActivityLogValue key={key}>{`${key}: ${value}`}</ActivityLogValue>
          ))}
        </ActivityLogContainer>
      ))}
    </ActivityLogTile>
  );
};
