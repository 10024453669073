import { IoIosLogOut } from 'react-icons/io';
import { styled } from 'styled-components';
import { useAuthentication } from '../../RequireAuthentication';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../buttons/Button';
import { useDispatch } from 'react-redux';
import { resetAll } from '../../store/actions.ts';

interface MainContentProps {
  children: React.ReactNode;
}

const OuterContainer = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  padding: 0px 32px 32px 32px;
  display: flex;
  flex: 1;
  height: 90vh;
`;

const ProfileOuter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-right: 32px;
`;

const Profile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: ${props => props.theme.colours.accent};
  color: ${props => props.theme.colours.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 80px;
  right: 32px;
  background-color: ${props => props.theme.colours.backgroundDark};
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  padding: 16px 16px 16px 16px;
`;

const SignOutButton = styled(Button)`
  border-top: 1px solid ${props => props.theme.colours.backgroundMedium};
`;

const DropdownItem = styled.div`
  cursor: pointer;
  margin-bottom: 16px;
`;

export const MainContent = ({ children }: MainContentProps) => {
  const { name } = useAuthentication();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event: { target: EventTarget | null }) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        profileRef.current &&
        !profileRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getInitials = () => {
    if (name) {
      const names = name.split(' ');
      return names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
    }
    return '';
  };

  const signOutUser = async () => {
    dispatch(resetAll());
    window.location.replace('/api/logout');
  };

  return (
    <OuterContainer>
      <ProfileOuter>
        <Profile ref={profileRef} onClick={() => setShowMenu(!showMenu)}>
          {getInitials()}
        </Profile>
      </ProfileOuter>
      {showMenu && (
        <Dropdown ref={dropdownRef}>
          <DropdownItem>{name}</DropdownItem>
          <SignOutButton
            label="Sign out"
            variant="transparent"
            IconType={IoIosLogOut}
            imagePosition="pre"
            onClick={signOutUser}
          />
        </Dropdown>
      )}
      <ContentContainer>{children}</ContentContainer>
    </OuterContainer>
  );
};
