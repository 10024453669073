import { useState } from 'react';
import { Button } from '../../components/buttons/Button.tsx';
import { Heading } from '../../components/Heading.tsx';
import { Modal } from '../../components/Modal.tsx';
import { styled } from 'styled-components';
import { IoIosAddCircle } from 'react-icons/io';
import { AddPatientForm } from './AddPatientForm.tsx';
import { PatientListView } from './PatientListView.tsx';
import { SearchInput } from '../../components/inputs/SearchInput.tsx';
import { useGetPatientsQuery } from '../../api';
import { Pagination } from '../../components/Pagination.tsx';
import { useDebounce } from '../../hooks/useDebounce.ts';
import { useListViewState } from '../../hooks/useListViewState.ts';

const PatientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 70px;
  justify-content: space-between;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PatientListConfigurators = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  height: 54px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

export const Patients = () => {
  const pageSize = 20;
  const defaultState = {
    page: 1,
    sorting: undefined,
    searchTerm: ''
  };

  const [{ page, sorting, searchTerm: searchTerm }, setListViewSorting, setListViewPage, setListViewSearchTerm] =
    useListViewState(defaultState, 'patientList');

  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState(searchTerm || '');
  useDebounce(searchInput, 600, setListViewSearchTerm);

  const { data: queryResult, isLoading } = useGetPatientsQuery({
    searchTerm: searchTerm || undefined,
    page: page || 1,
    sortBy: sorting?.field,
    sortOrder: sorting ? (sorting.ascending ? 'ASC' : 'DESC') : undefined
  });

  const totalPages = Math.ceil((queryResult?.totalPatients || 0) / pageSize);

  return (
    <PatientsContainer>
      {showModal && (
        <Modal title="Add a New Patient" onClose={() => setShowModal(false)}>
          <AddPatientForm closeModal={() => setShowModal(false)} />
        </Modal>
      )}
      <TopBar>
        <Heading size={'l'}>Patients</Heading>
        <PatientListConfigurators>
          <SearchInput value={searchInput} onChange={setSearchInput} />
          <Button
            label="Add Patient"
            variant="primary"
            IconType={IoIosAddCircle}
            imagePosition="pre"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </PatientListConfigurators>
      </TopBar>
      <Container>
        <PatientListView
          patients={queryResult?.patients || []}
          loading={isLoading}
          onSortingUpdated={setListViewSorting}
          sorting={sorting}
        />
        {!isLoading && <Pagination currentPage={page || 1} totalPages={totalPages} changePage={setListViewPage} />}
      </Container>
    </PatientsContainer>
  );
};
