import { styled } from 'styled-components';
import { ReactNode } from 'react';
import { Heading } from './Heading.tsx';

interface TileProps {
  title: string;
  children: ReactNode;
  className?: string;
}

const TileContainer = styled.div`
  background-color: ${props => props.theme.colours.backgroundDark};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ContentContainer = styled.div`
  margin-top: 8px;
  overflow-y: auto;
`;

export const Tile = ({ title, children, className }: TileProps) => {
  return (
    <TileContainer className={className}>
      <Heading size={'m'}>{title}</Heading>
      <ContentContainer>{children}</ContentContainer>
    </TileContainer>
  );
};
