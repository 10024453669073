import { Modal } from '../../components/Modal.tsx';
import { ErrorMessage, InputLabel, TextInput } from '../../components/inputs/TextInput.tsx';
import { styled } from 'styled-components';
import { useEffect, useState } from 'react';
import { useValidator } from '../../hooks/useValidator.ts';
import { constants } from '../../constants.ts';
import { Button } from '../../components/buttons/Button.tsx';
import { Spinner } from '../../components/Spinner.tsx';
import { UserUpdate, useUpdateUserMutation } from '../../api';
import { useAuthentication } from '../../RequireAuthentication.tsx';

type EditUserModalProps = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  onClose: () => void;
};

const UserForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 20px 10px;
`;

const InputContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const InputColumns = styled.div`
  display: flex;
  gap: 20px;

  > * {
    flex: 1;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const FormErrorContainer = styled.div`
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  margin-left: 20px;
`;

// TODO: Create reusable component for select box
const SelectBox = styled.select`
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  border: transparent;
  font-size: ${props => props.theme.fontSizes.sm};
  margin: 5px 0;
  color: ${props => props.theme.colours.light};
  background-color: ${props => props.theme.colours.backgroundMedium};

  &:disabled {
    background-color: ${props => props.theme.colours.backgroundLight};
  }

  &:focus {
    outline: none;
  }
`;

export const EditUser = ({ id, email, firstName, lastName, roles, onClose }: EditUserModalProps) => {
  const { id: currentUserId } = useAuthentication();
  const [formError, setFormError] = useState('');
  const [updateUser, { isLoading: isUpdating, error, isSuccess }] = useUpdateUserMutation();

  const [userUpdate, setUserUpdate] = useState<UserUpdate>({
    id: id,
    firstName: firstName,
    lastName: lastName,
    roles: roles
  });

  const { validator, validate } = useValidator({
    validEmail: {
      message: 'Please enter a valid email address',
      rule: (val: string) => {
        return constants.regExp.email.test(val);
      }
    },
    validName: {
      message: 'Please enter a valid name',
      rule: (val: string) => {
        return constants.regExp.findName.test(val);
      }
    },
    validRole: {
      message: 'Please select a valid role',
      rule: (val: string[]) => {
        return val.length > 0 && val.every(role => constants.roles.includes(role));
      }
    }
  });

  const onChange = (property: string, value: string | string[]) => {
    setUserUpdate({ ...userUpdate, [property]: value });
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    } else if (error) {
      setFormError('Error updating user');
    }
  }, [isSuccess, error, onClose]);

  const submit = async () => {
    if (!validate()) {
      return;
    }

    await updateUser(userUpdate);
  };

  return (
    <UserForm>
      <InputColumns>
        <InputContainer>
          <TextInput
            variant={'primary'}
            value={email}
            label="Email address"
            placeholder="Enter address"
            onChange={value => onChange('email', value)}
            disabled={true}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="input-field">Role</InputLabel>
          <SelectBox
            onChange={value => onChange('roles', [value.currentTarget.value])}
            value={userUpdate.roles.length > 0 ? userUpdate.roles[0] : 'clinician'}
            disabled={currentUserId === id && userUpdate.roles.length === 1 && userUpdate.roles[0] === 'admin'}
          >
            <option value="admin">Admin</option>
            <option value="clinician">Clinician</option>
          </SelectBox>
          <ErrorMessage>{validator.current.message('roles', userUpdate.roles, 'required|validRole')}</ErrorMessage>
        </InputContainer>
      </InputColumns>
      <InputColumns>
        <InputContainer>
          <TextInput
            variant={'primary'}
            value={userUpdate.firstName}
            label="First name"
            placeholder="Enter first name"
            onChange={value => onChange('firstName', value)}
            errorMessage={validator.current.message('firstName', userUpdate.firstName, 'required|validName')}
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            variant={'primary'}
            value={userUpdate.lastName}
            label="Last name"
            placeholder="Enter last name"
            onChange={value => onChange('lastName', value)}
            errorMessage={validator.current.message('lastName', userUpdate.lastName, 'required|validName')}
          />
        </InputContainer>
      </InputColumns>
      <ButtonContainer>
        <Button label="Save" variant={isUpdating ? 'disabled' : 'accent'} onClick={() => submit()} />
        {isUpdating && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
      </ButtonContainer>
      {formError && <FormErrorContainer>{formError}</FormErrorContainer>} {/*Move this to toast or similar*/}
    </UserForm>
  );
};

export const EditUserModal = ({ onClose, ...props }: EditUserModalProps) => {
  return (
    <Modal title="Edit User" onClose={onClose}>
      <EditUser onClose={onClose} {...props} />
    </Modal>
  );
};
