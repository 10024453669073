import {
  ListView,
  ListViewColumnDefinition,
  ListViewFieldProps,
  ListViewRow,
  ListViewSorting,
  rowsFromColumnDefinitions
} from '../../components/table/listview';
import { styled } from 'styled-components';
import { Patient } from '../../api';
import { useNavigate } from 'react-router-dom';

interface PatientListViewProps {
  patients: Patient[];
  loading: boolean;
  onSortingUpdated?: (sorting?: ListViewSorting) => void;
  sorting: ListViewSorting | undefined;
}

const columnDefinitions: ListViewColumnDefinition[] = [
  { field: 'firstName', header: 'First Name' },
  { field: 'lastName', header: 'Last Name' },
  {
    field: 'dateOfStroke',
    header: 'Date of Stroke',
    fieldComponent: ({ value }: ListViewFieldProps) => {
      const date = new Date(value as string);
      return <>{date.toLocaleDateString()}</>;
    }
  }
];

const ListViewContainer = styled.div`
  height: 60vh;
  margin-top: 20px;
  overflow: auto;
`;

export const PatientListView = ({ patients, loading, onSortingUpdated, sorting }: PatientListViewProps) => {
  const navigate = useNavigate();
  const rows = rowsFromColumnDefinitions(patients, columnDefinitions);

  return (
    <ListViewContainer>
      <ListView
        rows={rows}
        columns={columnDefinitions}
        sorting={sorting}
        onSortingUpdated={onSortingUpdated}
        isLoading={loading}
        onItemSelected={(selected: ListViewRow) => navigate(`/patients/${(selected.data as Patient).id}`)}
      />
    </ListViewContainer>
  );
};
