import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { Patients } from './pages/Patients';
import { PatientDetails } from './pages/PatientDetails';
import { styled } from 'styled-components';
import { NotFound } from './NotFound';
import { RequireAuthentication } from './RequireAuthentication';
import { Layout } from './components/layout';
import { SignIn, SignInCallback } from './pages/SignIn.tsx';
import { Admin } from './pages/admin';

const AppContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<RequireAuthentication />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/patients" replace={true} state={{ resetView: true }} />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/patients/:id" element={<PatientDetails />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-in/callback" element={<SignInCallback />} />
      </Route>
    )
  );

  return (
    <AppContainer>
      <RouterProvider router={router} />
    </AppContainer>
  );
};
