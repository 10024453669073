import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { tms } from '../api/tms.ts';
import patientViewReducer, { PatientViewState } from './slices/patientView.ts';
import authReducer from './slices/auth.ts';

const persistConfig = { key: 'root', storage };

const rootReducer = combineReducers({
  [tms.reducerPath]: tms.reducer,
  patientView: patientViewReducer,
  auth: persistReducer(persistConfig, authReducer)
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/FLUSH',
          'persist/REHYDRATE',
          'persist/PAUSE',
          'persist/PERSIST',
          'persist/PURGE',
          'persist/REGISTER'
        ]
      }
    }).concat([tms.middleware])
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer> & {
  patientView: PatientViewState;
};

export type AppDispatch = typeof store.dispatch;
