import { styled } from 'styled-components';
import logo from '../assets/bioliberty_logo.png';
import { Button } from '../components/buttons/Button.tsx';
import Env from '../Env.ts';
import { Heading } from '../components/Heading.tsx';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from '../components/Spinner.tsx';
import { useExchangeAuthorizationCodeMutation } from '../api/auth.ts';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setToken } from '../store/slices/auth.ts';

const Layout = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const Logo = styled.img`
  flex: 0;
  padding-bottom: 3rem;
`;

const onSignIn = () => {
  if (Env.IS_DEV) {
    console.log('Running in dev mode, redirecting to /');
    window.location.replace('/');
    return;
  } else {
    window.location.replace('/api/authorize');
    return;
  }
};

export const SignIn = () => {
  const location = useLocation();
  const loginMessage =
    location.state?.loggedOut === true ? 'You have been signed out.' : 'You are not currently signed in.';

  return (
    <Layout>
      <Logo src={logo} alt="logo" />
      <Heading size="m">{loginMessage}</Heading>
      <Button label="Sign In" variant="accent" onClick={onSignIn} />
    </Layout>
  );
};

export const SignInCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const successfulCallback = code && state;

  const [exchangeAuthorizationCode, { error, isSuccess, data }] = useExchangeAuthorizationCodeMutation();

  useEffect(() => {
    if (successfulCallback) {
      exchangeAuthorizationCode({ authorizationCode: code, state: state });
    }
  }, [successfulCallback, code, state, exchangeAuthorizationCode]);

  useEffect(() => {
    if (!isSuccess) return;

    if (data && data.accessToken) {
      dispatch(setToken(data));
    }
  }, [isSuccess, data]);

  if (isSuccess) {
    navigate('/');
    return null;
  }

  return (
    <Layout>
      <Logo src={logo} alt="logo" />
      {successfulCallback && !error ? <SigningIn /> : <SignInFailed />}
    </Layout>
  );
};

const SigningIn = () => {
  return (
    <>
      <Heading size="m">Signing in...</Heading>
      <Spinner />
    </>
  );
};

const SignInFailed = () => {
  return (
    <>
      <Heading size="m">Something went wrong</Heading>
      <p>
        We were unable to sign you in. <NavLink to={'/sign-in'}>Try to sign in again</NavLink>
      </p>
    </>
  );
};
