import { IconType } from 'react-icons';
import { styled } from 'styled-components';
import { Icon } from '../Icon.tsx';

interface IconButtonProps {
  onClick: () => void;
  variant: 'accent' | 'danger' | 'transparent' | 'noHover';
  IconType: IconType;
  disabled?: boolean;
}

const BasicIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:disabled {
    opacity: 0.25;
    cursor: default;
  }
`;

const IconButtonAccent = styled(BasicIconButton)`
  background-color: ${props => props.theme.colours.accent};
  color: ${props => props.theme.colours.primary};
  transition: opacity 0.2s ease-in-out;

  &:enabled:hover {
    opacity: 0.7;
  }
`;

const IconButtonTransparent = styled(BasicIconButton)`
  background-color: transparent;
  color: ${props => props.theme.colours.light};
  transition: background-color 0.2s ease-in-out;

  &:enabled:hover {
    background-color: ${props => props.theme.colours.subtle};
  }
`;

const IconButtonTransparentDanger = styled(BasicIconButton)`
  background-color: transparent;
  color: ${props => props.theme.colours.light};
  transition: background-color 0.2s ease-in-out;

  &:enabled:hover {
    background-color: ${props => props.theme.colours.danger};
  }
`;

const IconButtonNoHover = styled(BasicIconButton)`
  background-color: transparent;
  color: ${props => props.theme.colours.light};
`;

export const IconButton = ({ onClick, variant, IconType, disabled }: IconButtonProps) => {
  const IconBody = () => {
    return <Icon size={'fillParent'} IconType={IconType} />;
  };

  if (variant === 'accent') {
    return (
      <IconButtonAccent onClick={onClick} disabled={disabled}>
        {IconBody()}
      </IconButtonAccent>
    );
  } else if (variant === 'danger') {
    return (
      <IconButtonTransparentDanger onClick={onClick} disabled={disabled}>
        {IconBody()}
      </IconButtonTransparentDanger>
    );
  } else if (variant === 'transparent') {
    return (
      <IconButtonTransparent onClick={onClick} disabled={disabled}>
        {IconBody()}
      </IconButtonTransparent>
    );
  } else if (variant === 'noHover') {
    return (
      <IconButtonNoHover onClick={onClick} disabled={disabled}>
        {IconBody()}
      </IconButtonNoHover>
    );
  } else {
    return (
      <IconButtonTransparent onClick={onClick} disabled={disabled}>
        {IconBody()}
      </IconButtonTransparent>
    );
  }
};
