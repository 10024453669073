import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { getToken } from '../store/slices/auth.ts';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = getToken(state)?.accessToken;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
  }
});

export const tms = createApi({
  reducerPath: 'tms',
  baseQuery: baseQuery,
  tagTypes: ['Patient', 'PatientActivityLog', 'Users'],
  endpoints: () => ({
    // As a general rule, no endpoints should be registered here
    // Instead, they should be registered in the `injectEndpoints` method in the
    // appropriate file in the `api` directory
  })
});
