import { tms } from './tms';

export type Token = {
  accessToken: string;
  expiresIn: number;
  idToken: string;
};

export type ExchangeAuthorizationCode = {
  authorizationCode: string;
  state: string;
};

const auth = tms.injectEndpoints({
  endpoints: builder => ({
    exchangeAuthorizationCode: builder.mutation<Token, ExchangeAuthorizationCode>({
      query: command => ({
        url: `authorize/token`,
        method: 'POST',
        body: command
      })
    })
  }),
  overrideExisting: false
});

export const { useExchangeAuthorizationCodeMutation } = auth;
