import { tms } from './tms';

export type BasePatientActivityLog = {
  id: number;
  patientId: number;
  activityType: string;
  completedOn: string;
  feedbackScore?: number;
  timeTaken: number;
  gloveSpeedDifficulty?: string;
};

export type PatientExtMobCPMLog = BasePatientActivityLog & {
  totalRepCount: number;
  repsCompleted: number;
  holdTime: number;
  restTime: number;
}

export type PatientExtMobAssistLog = BasePatientActivityLog & {
  totalRepCount: number;
  repsCompleted: number;
  holdTime: number;
  restTime: number;
  independenceScore: number;
  enduranceScore: number;
}

export type PatientManualModeLog = BasePatientActivityLog

export type PatientGameModeLog = BasePatientActivityLog

export type PatientActivityLog =
  PatientExtMobCPMLog
  | PatientExtMobAssistLog
  | PatientManualModeLog
  | PatientGameModeLog;

interface GetPatientActivityLogsResult {
  patientActivityLogs: PatientActivityLog[];
  totalPatientActivityLogs: number;
}

const activityLog = tms.injectEndpoints({
  endpoints: builder => ({
    getPatientActivityLogs: builder.query<GetPatientActivityLogsResult, string>({
      query: patientId => ({
        url: `patients/${patientId}/activity-logs`,
        method: 'GET',
        params: { page: '1' }
      }),
      providesTags: (_result, _error, patientId) => [{ type: 'PatientActivityLog', id: patientId }]
    })
  }),
  overrideExisting: false
});

export const { useQuery: useGetPatientActivityLogsQuery } = activityLog.endpoints.getPatientActivityLogs;
