import { styled } from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { ErrorMessage, InputLabel } from './TextInput.tsx';

interface DatePickerInputProps {
  initialValue?: Date;
  onChange: (date: Date) => void;
  label: string;
  maxDate?: Date;
  errorMessage?: string;
}

const DatePickerWrapper = styled.div`
  .react-datepicker__header {
    background-color: ${props => props.theme.colours.backgroundMedium};
    color: ${props => props.theme.colours.light};
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .react-datepicker {
    font-family: inherit;
    border: none;
    margin-left: 40px;
    border-radius: 20px;
  }

  .react-datepicker__month {
    min-height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: ${props => props.theme.colours.light};
  }

  .react-datepicker-popper {
    left: 20px;
  }

  .react-datepicker__day {
    color: ${props => props.theme.colours.dark};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: ${props => props.theme.colours.accent};
  }

  .react-datepicker__day--outside-month {
    color: ${props => props.theme.colours.subtle};
  }

  .react-datepicker__day--disabled {
    color: ${props => props.theme.colours.outline};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${props => props.theme.colours.light};
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container input {
    width: 100%;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 15px;
  border-radius: 15px;
  border: transparent;
  font-size: ${props => props.theme.fontSizes.sm};
  margin: 5px 0;
  background-color: ${props => props.theme.colours.backgroundMedium};
  color: ${props => props.theme.colours.light};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${props => props.theme.colours.outline};
  }
`;

export const DatePickerInput = ({ initialValue, onChange, label, maxDate, errorMessage }: DatePickerInputProps) => {
  const [startDate, setStartDate] = useState(initialValue);

  const updateDate = (e: Date) => {
    setStartDate(e);
    onChange(e);
  };

  return (
    <DatePickerWrapper>
      {label && <InputLabel htmlFor="input-field">{label}</InputLabel>}
      <StyledDatePicker
        selected={startDate}
        onChange={(date: Date) => {
          updateDate(date);
        }}
        maxDate={maxDate}
        dateFormat={'dd/MM/yyyy'} // TODO: Set this to the user's locale
        placeholderText="Select a date"
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </DatePickerWrapper>
  );
};
