import { styled } from 'styled-components';
import { TextInput } from './TextInput';
import { FiSearch } from 'react-icons/fi';

interface SearchInputProps {
  value: string;
  onChange: (e: string) => void;
}

const SearchBox = styled.div`
  background-color: ${props => props.theme.colours.primary};
  color: ${props => props.theme.colours.light};
  border: 2px solid ${props => props.theme.colours.outline};
  display: flex;
  align-items: center;
  border-radius: 40px;
  height: 54px;
  min-width: 120px;
`;

const SpanLeft = styled.span`
  padding-left: 11px;
`;

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colours.light};
  color: ${props => props.theme.colours.primary};
  border-radius: 50%;
  width: 26px;
  height: 26px;
`;

export const SearchInput = ({ value, onChange }: SearchInputProps) => {
  return (
    <SearchBox>
      <SpanLeft>
        <IconCircle>
          <FiSearch />
        </IconCircle>
      </SpanLeft>
      <TextInput variant={'transparent'} value={value} placeholder="Search" onChange={onChange} />
    </SearchBox>
  );
};
