import { tms } from './tms';
import { TokenizedPagedList } from './responses.ts';

export type Profile = {
  id: string;
  name: string;
  email: string;
  roles: string[];
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
};

export type UserInvite = {
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
};

export type UserUpdate = {
  id: string;
  firstName: string;
  lastName: string;
  roles: string[];
};

const users = tms.injectEndpoints({
  endpoints: builder => ({
    getProfile: builder.query<Profile, void>({
      query: () => `profile`
    }),

    getUsers: builder.query<TokenizedPagedList<User>, { pageToken?: string }>({
      query: ({ pageToken }) => {
        const query = pageToken ? `?page=${encodeURIComponent(pageToken)}` : '';
        return `users${query}`;
      },
      providesTags: ['Users']
    }),

    inviteUser: builder.mutation<void, UserInvite>({
      query: userInvite => ({
        url: `users`,
        method: 'POST',
        body: userInvite
      }),
      invalidatesTags: ['Users']
    }),

    updateUser: builder.mutation<void, UserUpdate>({
      query: userUpdate => ({
        url: `users/${userUpdate.id}`,
        method: 'PUT',
        body: userUpdate
      }),
      invalidatesTags: ['Users']
    }),

    deleteUser: builder.mutation<void, string>({
      query: id => ({
        url: `users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users']
    })
  }),
  overrideExisting: false
});

export const {
  useGetProfileQuery,
  useGetUsersQuery,
  useInviteUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation
} = users;
