import { styled } from 'styled-components';

interface TextInputProps {
  value: string;
  variant: 'primary' | 'transparent';
  label?: string;
  placeholder: string;
  onChange: (e: string) => void;
  errorMessage?: string;
  disabled?: boolean;
}

const StyledInput = styled.input`
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  border: transparent;
  font-size: ${props => props.theme.fontSizes.sm};
  margin: 5px 0;
  color: ${props => props.theme.colours.light};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${props => props.theme.colours.backgroundLight};
  }
`;

const PrimaryInput = styled(StyledInput)`
  background-color: ${props => props.theme.colours.backgroundMedium};

  &::placeholder {
    color: ${props => props.theme.colours.outline};
  }
`;

const TransparentInput = styled(StyledInput)`
  background-color: transparent;

  &::placeholder {
    color: ${props => props.theme.colours.light};
  }
`;

export const InputLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colours.light};
  margin: 8px 4px 4px 8px;
  display: block;
`;

export const ErrorMessage = styled.div`
  font-size: ${props => props.theme.fontSizes.xsm};
  color: orange;
  margin: 0 0 0 8px;
  height: 0; // stops form height from jumping
`;

//value left in for actual usage. The current implementation works in storybook but will change for the app
export const TextInput = ({ variant, label, placeholder, onChange, value, errorMessage, disabled }: TextInputProps) => {
  const changeText = (e: string) => {
    onChange(e);
  };

  const InputBody = () => {
    if (variant === 'primary') {
      return (
        <PrimaryInput
          value={value}
          placeholder={placeholder}
          onChange={e => changeText(e.target.value)}
          disabled={disabled}
        />
      );
    } else if (variant === 'transparent') {
      return (
        <TransparentInput
          value={value}
          placeholder={placeholder}
          onChange={e => changeText(e.target.value)}
          disabled={disabled}
        />
      );
    } else {
      return (
        <PrimaryInput
          value={value}
          placeholder={placeholder}
          onChange={e => changeText(e.target.value)}
          disabled={disabled}
        />
      );
    }
  };

  return (
    <>
      {label && <InputLabel htmlFor="input-field">{label}</InputLabel>}
      {InputBody()}
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  );
};
