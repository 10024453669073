import { useNavigate, useParams } from 'react-router-dom';
import { useDeletePatientMutation, useGetPatientActivityLogsQuery, useGetPatientQuery } from '../../api';
import { Heading } from '../../components/Heading.tsx';
import { styled } from 'styled-components';
import { Button } from '../../components/buttons/Button.tsx';
import { GoPencil } from 'react-icons/go';
import { NotFound } from '../../NotFound.tsx';
import { IoArrowBackOutline } from 'react-icons/io5';
import { Modal } from '../../components/Modal.tsx';
import { useState } from 'react';
import { EditPatientForm } from './EditPatientForm.tsx';
import { Spinner } from '../../components/Spinner.tsx';
import { PersonalDetailsContent } from './PersonalDetailsTile.tsx';
import { ActivityLogContent } from './ActivityLogTile.tsx';

const PatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 70px;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopBarButtons = styled.div`
  display: flex;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;

const DeleteContent = styled.div`
  margin-bottom: 20px;
`;

const ModalContent = styled.div`
  padding: 15px;
`;

const PatientDataRow = styled.div`
  display: flex;
  padding-bottom: 20px;
  max-height: 100%;
`;

const PatientDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ActionContainer = styled.div`
  display: flex;
`;

export const PatientDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePatient, { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess }] = useDeletePatientMutation();

  const navigate = useNavigate();

  const { id } = useParams();
  const { data: patient, error } = useGetPatientQuery(id as string, {
    skip: isDeleteSuccess || isDeleteLoading
  });

  const { data: activityLogQueryResult } = useGetPatientActivityLogsQuery(id as string);

  // TODO: add more error handling and a loading state
  if (error && 'status' in error && error.status === 404) {
    return <NotFound />;
  }

  const removePatient = async () => {
    await deletePatient(id as string);
    navigate('/patients');
  };

  return (
    <PatientContainer>
      {showModal && patient && (
        <Modal title="Edit a Patient" onClose={() => setShowModal(false)}>
          <EditPatientForm oldPatient={patient} closeModal={() => setShowModal(false)} />
        </Modal>
      )}
      {showDeleteModal && patient && (
        <Modal title="Delete a Patient" onClose={() => setShowDeleteModal(false)}>
          <ModalContent>
            <DeleteContent>Are you sure you want to delete this patient?</DeleteContent>
            <ActionContainer>
              <Button label="Delete" variant={isDeleteLoading ? 'disabled' : 'accent'} onClick={removePatient} />
              {isDeleteLoading && <Spinner />}
            </ActionContainer>
          </ModalContent>
        </Modal>
      )}

      <TopBar>
        <Heading size={'l'}>Patients</Heading>
        <TopBarButtons>
          {patient && (
            <Button
              label="Edit Patient"
              variant="primary"
              IconType={GoPencil}
              imagePosition="pre"
              onClick={() => {
                setShowModal(true);
              }}
            />
          )}
          <Button
            label="Back"
            variant="primary"
            IconType={IoArrowBackOutline}
            imagePosition="pre"
            onClick={() => navigate('..')}
          />
        </TopBarButtons>
      </TopBar>
      {patient ? (
        <PatientDataRow>
          <PatientDataColumn>
            <PersonalDetailsContent patient={patient} />
            <ButtonContainer>
              <Button label="Delete Patient" variant="danger" onClick={() => setShowDeleteModal(true)} />
            </ButtonContainer>
          </PatientDataColumn>
          <PatientDataColumn>
            {activityLogQueryResult && ActivityLogContent(activityLogQueryResult.patientActivityLogs)}
          </PatientDataColumn>
        </PatientDataRow>
      ) : (
        <Spinner />
      )}
    </PatientContainer>
  );
};
