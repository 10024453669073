import { styled } from 'styled-components';
import { IconType } from 'react-icons';
import { Icon } from '../Icon.tsx';

interface ButtonProps {
  label: string;
  variant: 'primary' | 'subtle' | 'accent' | 'disabled' | 'transparent' | 'danger';
  imagePosition?: 'pre' | 'post';
  onClick: () => void;
  IconType?: IconType;
}

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpanLeft = styled.span`
  padding-left: 11px;
`;

const SpanRight = styled.span`
  padding-right: 11px;
`;

const BasicButton = styled.button`
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 600;
  padding: 11px 22px 11px 22px;
  border: 2px solid ${props => props.theme.colours.outline};
  border-radius: 40px;
  height: 54px;
  cursor: pointer;
`;

const ButtonTransparent = styled.button`
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 600;
  padding: 11px 22px 11px 22px;
  height: 54px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.colours.light};
`;

const ButtonPrimary = styled(BasicButton)`
  background-color: ${props => props.theme.colours.primary};
  color: ${props => props.theme.colours.light};
  border: 2px solid ${props => props.theme.colours.outline};
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colours.light};
    color: ${props => props.theme.colours.primary};
  }
`;

const ButtonSubtle = styled(BasicButton)`
  background-color: ${props => props.theme.colours.subtle};
  color: ${props => props.theme.colours.light};
  border: 2px solid ${props => props.theme.colours.subtle};
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

const ButtonAccent = styled(BasicButton)`
  background-color: ${props => props.theme.colours.accent};
  color: ${props => props.theme.colours.dark};
  border: 2px solid ${props => props.theme.colours.accent};
  box-shadow: 0 4px 16px 0 rgba(0, 220, 237, 0.5);
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

const ButtonDanger = styled(BasicButton)`
  background-color: ${props => props.theme.colours.danger};
  color: ${props => props.theme.colours.light};
  border: 2px solid ${props => props.theme.colours.danger};
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

const ButtonDisabled = styled(BasicButton)`
  background-color: ${props => props.theme.colours.outline};
  color: ${props => props.theme.colours.subtle};
  border: 2px solid ${props => props.theme.colours.outline};
  pointer-events: none;
`;

export const Button = ({ label, variant, imagePosition, onClick, IconType }: ButtonProps) => {
  const ButtonBody = () => {
    if (IconType && imagePosition) {
      if (imagePosition === 'pre') {
        return (
          <ButtonDiv>
            <Icon IconType={IconType} size={'l'} />
            <SpanLeft>{label}</SpanLeft>
          </ButtonDiv>
        );
      } else if (imagePosition === 'post') {
        return (
          <ButtonDiv>
            <SpanRight>{label}</SpanRight>
            <Icon IconType={IconType} size={'l'} />
          </ButtonDiv>
        );
      }
    } else {
      return <ButtonDiv>{label}</ButtonDiv>;
    }
  };

  if (variant == 'primary') {
    return <ButtonPrimary onClick={onClick}>{ButtonBody()}</ButtonPrimary>;
  } else if (variant == 'subtle') {
    return <ButtonSubtle onClick={onClick}>{ButtonBody()}</ButtonSubtle>;
  } else if (variant == 'accent') {
    return <ButtonAccent onClick={onClick}>{ButtonBody()}</ButtonAccent>;
  } else if (variant == 'disabled') {
    return <ButtonDisabled onClick={onClick}>{ButtonBody()}</ButtonDisabled>;
  } else if (variant == 'transparent') {
    return <ButtonTransparent onClick={onClick}>{ButtonBody()}</ButtonTransparent>;
  } else if (variant == 'danger') {
    return <ButtonDanger onClick={onClick}>{ButtonBody()}</ButtonDanger>;
  } else {
    return <ButtonPrimary onClick={onClick}>{ButtonBody()}</ButtonPrimary>;
  }
};
