import { NavLink, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { Heading } from '../Heading';
import { IoMdPeople } from 'react-icons/io';
import { MdSettings } from 'react-icons/md';
import { Icon } from '../Icon.tsx';
import { useAuthentication } from '../../RequireAuthentication.tsx';

const Item = styled.div<{ $isActive: boolean }>`
  padding: 16px 16px 16px 16px;
  margin-bottom: 12px;
  border: 2px solid ${props => props.theme.colours.backgroundMedium};
  border-radius: 12px;
  max-width: 300px;
  cursor: pointer;
  background-color: ${props => props.$isActive && props.theme.colours.backgroundMedium};
  display: flex;
  align-items: center;
`;

const TextContainer = styled.div`
  margin-left: 10px;
`;

const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  a {
    text-decoration: none;
    color: ${props => props.theme.colours.light};
  }
`;

export const Nav = () => {
  const location = useLocation();
  const { roles } = useAuthentication();

  const navItemIsActive = (rootPath: string) => {
    return location.pathname.startsWith(rootPath);
  };

  return (
    <ListContainer>
      <ListItem>
        <NavLink to="/patients" state={{ resetView: true }}>
          <Item $isActive={navItemIsActive('/patients')}>
            <Icon IconType={IoMdPeople} size={'m'} />
            <TextContainer>
              <Heading size="sm">Patients</Heading>
            </TextContainer>
          </Item>
        </NavLink>

        {roles.includes('admin') && (
          <NavLink to="/admin" state={{ resetView: true }}>
            <Item $isActive={navItemIsActive('/admin')}>
              <Icon IconType={MdSettings} size={'m'} />
              <TextContainer>
                <Heading size="sm">Admin</Heading>
              </TextContainer>
            </Item>
          </NavLink>
        )}
      </ListItem>
    </ListContainer>
  );
};
