import { Outlet } from 'react-router-dom';
import { styled } from 'styled-components';
import { Sidebar } from '../sidebar';
import { MainContent } from './MainContent';

const AppRoot = styled.section`
  height: 100%;
  display: flex;
  flex: 1;
`;

export const Layout = () => {
  return (
    <AppRoot>
      <Sidebar />
      <MainContent>
        <Outlet />
      </MainContent>
    </AppRoot>
  );
};
