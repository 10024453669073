import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index.ts';
import { RESET_ALL_ACTION } from '../actions.ts';

type ViewState = {
  listView?: ListViewState;
};

type ListViewState = {
  page?: number;
  sorting?: {
    field: string;
    ascending: boolean;
  };
  searchTerm?: string;
};

export type PatientViewState = {
  locations: { [location: string]: ViewState };
};

const initialState: PatientViewState = {
  locations: {}
};

export type LocationViewState = {
  location: string;
  viewState: ViewState;
};

const slice = createSlice({
  name: 'patientView',
  initialState,
  extraReducers: builder => builder.addCase(RESET_ALL_ACTION, () => initialState),
  reducers: {
    setPatientViewState: (state, { payload: viewState }: PayloadAction<LocationViewState>) => {
      state.locations[viewState.location] = viewState.viewState;
    }
  }
});

export default slice.reducer;

export const { setPatientViewState } = slice.actions;

export const getViewStateForLocation =
  (location: string) =>
  (state: RootState): ViewState | null => {
    return state.patientView.locations[location] || null;
  };
