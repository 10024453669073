import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getViewStateForLocation, LocationViewState, setPatientViewState } from '../store/slices/patientView.ts';
import { ListViewSorting } from '../components/table/listview';

type ListViewState = {
  page?: number;
  sorting?: ListViewSorting;
  searchTerm?: string;
};

type ListViewStateHook = [
  ListViewState,
  (sorting?: ListViewSorting) => void,
  (page: number) => void,
  (searchTerm?: string) => void,
  () => void
];

export const useListViewState = (defaultState?: ListViewState, listViewName?: string): ListViewStateHook => {
  const dispatch = useDispatch();
  const location = useLocation();
  const alias = listViewName ? `#${listViewName}` : location.hash;
  const defaultViewState = defaultState || {};
  const viewState = useSelector(getViewStateForLocation(location.pathname + alias));
  const [state, setState] = useState<ListViewState>(viewState?.listView || defaultViewState);

  useEffect(() => {
    const shouldResetState = location.state?.resetView === true;
    if (shouldResetState) {
      setState(defaultViewState);
    }
  }, [location.state]);

  useEffect(() => {
    const viewState: LocationViewState = {
      location: location.pathname + alias,
      viewState: {
        listView: {
          page: state.page || defaultState?.page,
          sorting: state.sorting || defaultState?.sorting,
          searchTerm: state.searchTerm || defaultState?.searchTerm
        }
      }
    };

    dispatch(setPatientViewState(viewState));
  }, [state]);

  const setListViewSorting = (sorting?: ListViewSorting) => {
    setState({ ...state, sorting });
  };

  const setListViewPage = (page: number) => {
    setState({ ...state, page });
  };

  const setListViewSearchTerm = (searchTerm?: string) => {
    if (searchTerm != state.searchTerm) setState({ ...state, searchTerm, page: 1 });
  };

  const resetListViewState = () => {
    setState(defaultState || {});
  };

  return [state, setListViewSorting, setListViewPage, setListViewSearchTerm, resetListViewState];
};
