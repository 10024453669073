import { ReactNode } from 'react';
import { IconContext, IconType } from 'react-icons';
import { styled } from 'styled-components';

interface IconProps {
  IconType: IconType;
  size: 'sm' | 'm' | 'l' | 'fillParent';
}

interface IconContextProviderProps {
  className?: string;
  children: ReactNode;
}

const IconContextProvider = ({ className, children }: IconContextProviderProps) => (
  <IconContext.Provider value={{ className: className }}>{children}</IconContext.Provider>
);

const SmIcon = styled(IconContextProvider)`
  font-size: 16px;
`;

const MIcon = styled(IconContextProvider)`
  font-size: 20px;
`;

const LIcon = styled(IconContextProvider)`
  font-size: 24px;
`;

const FillParentIcon = styled(IconContextProvider)`
  width: 100%;
  height: 100%;
`;

export const Icon = ({ IconType, size }: IconProps) => {
  if (size === 'sm')
    return (
      <SmIcon>
        <IconType />
      </SmIcon>
    );
  else if (size === 'm')
    return (
      <MIcon>
        <IconType />
      </MIcon>
    );
  else if (size === 'l')
    return (
      <LIcon>
        <IconType />
      </LIcon>
    );
  else
    return (
      <FillParentIcon>
        <IconType />
      </FillParentIcon>
    );
};
