import { styled } from 'styled-components';
import bioliberty_logo from '../../assets/bioliberty_logo.png';
import { Nav } from './Nav';
import { NavLink } from 'react-router-dom';

const SidebarContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 32px 32px;
  height: 100%;
  background-color: ${props => props.theme.colours.backgroundDark};
`;

const Logo = styled.img`
  width: 75%;
  margin-bottom: 40px;
`;

export const Sidebar = () => {
  return (
    <SidebarContainer>
      <NavLink to="/">
        <Logo src={bioliberty_logo} alt="Bioliberty Logo" />
      </NavLink>
      <Nav />
    </SidebarContainer>
  );
};
