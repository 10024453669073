import { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface TableProps {
  fixedHeader?: boolean;
  highlightRows?: boolean;
  children?: ReactElement<TableHeaderProps> | ReactElement<TableHeaderProps>[];
}

interface TableHeaderProps {
  children?: ReactElement<TableColumnProps> | ReactElement<TableColumnProps>[];
}

interface TableColumnProps {
  width?: string;
  onClick?: () => void;
  children?: ReactNode;
}

interface TableBodyProps {
  children?: ReactElement<TableRowProps> | ReactElement<TableRowProps>[];
}

interface TableRowProps {
  highlighted?: boolean;
  onClick?: () => void;
  children?: ReactElement<TableCellProps> | ReactElement<TableCellProps>[];
}

interface TableCellProps {
  cellNoWrap?: boolean;
  children?: ReactNode;
}

const TableRoot = styled.table<{ $fixedHeader: boolean; $highlightRows: boolean }>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5em;
  overflow: auto;
  font-size: ${props => props.theme.fontSizes.sm};
  margin-top: -0.5em;

  thead {
    th {
      ${props =>
        props.$fixedHeader &&
        css`
          position: sticky;
          top: 0;
        `}

      background: ${props => props.theme.colours.primary};
      height: 64px;
      text-align: left;
      padding: 4px 4px;
    }
  }

  ${props =>
    props.$highlightRows &&
    css`
      tbody {
        tr td {
          transition: background-color 0.2s ease-in;
        }

        tr:hover td {
          background: ${props => props.theme.colours.backgroundMedium};
          cursor: pointer;
        }
      }
    `}
`;

export const Table = ({ fixedHeader, highlightRows, children }: TableProps) => {
  return (
    <TableRoot $fixedHeader={fixedHeader || false} $highlightRows={highlightRows || false}>
      {children}
    </TableRoot>
  );
};

export const TableHeader = ({ children }: TableHeaderProps) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};

const Column = styled.th<{ $clickable: boolean }>`
  ${props =>
    props.$clickable &&
    css`
      cursor: pointer;
      user-select: none;
    `}
`;

export const TableColumn = ({ width, onClick, children }: TableColumnProps) => {
  const style = {
    width: width || 'auto',
    paddingLeft: '16px'
  };

  const isClickable = onClick !== undefined && onClick !== null;
  const onColumnClick = () => {
    if (isClickable) {
      onClick();
    }
  };

  return (
    <Column style={style} $clickable={isClickable} onClick={onColumnClick}>
      {children}
    </Column>
  );
};

export const TableBody = styled.tbody<TableBodyProps>``;

const Row = styled.tr<{ $highlighted: boolean }>`
  ${props =>
    props.$highlighted &&
    css`
      background: ${props => props.theme.colours.backgroundMedium};
    `}
`;

export const TableRow = ({ highlighted, onClick, children }: TableRowProps) => {
  const onRowClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Row $highlighted={highlighted || false} onClick={onRowClick}>
      {children}
    </Row>
  );
};

export const TableCell = styled.td<TableCellProps>`
  padding: 0 16px;
  height: 60px;
  line-height: 20px;
  white-space: ${props => (props.cellNoWrap ? 'nowrap' : 'normal')};
  overflow: ${props => (props.cellNoWrap ? 'hidden' : 'visible')};
  text-overflow: ${props => (props.cellNoWrap ? 'ellipsis' : 'clip')};
  background-color: ${props => props.theme.colours.backgroundDark};

  &:first-of-type {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  &:last-of-type {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
`;
