import { useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

export const useValidator = (customValidators = {}) => {
  const validator = useRef(
    new SimpleReactValidator({
      // These are for extra custom validators that are not included by default
      // https://github.com/dockwa/simple-react-validator?tab=readme-ov-file#rules
      validators: customValidators
    })
  );
  const [, setErrors] = useState({});

  const validate = () => {
    if (validator.current.allValid()) {
      return true;
    } else {
      validator.current.showMessages();
      setErrors(validator.current.getErrorMessages());
      return false;
    }
  };

  return { validator, validate };
};
