import { styled } from 'styled-components';

interface HeadingProps {
  children: React.ReactNode;
  size: 'sm' | 'm' | 'l';
}

const BaseHeading = styled.h2`
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

const HeadingSm = styled(BaseHeading)`
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: ${props => props.theme.fontWeights.thin};
`;

const HeadingM = styled(BaseHeading)`
  font-size: ${props => props.theme.fontSizes.m};
  font-weight: ${props => props.theme.fontWeights.regular};
`;

const HeadingL = styled(BaseHeading)`
  font-size: ${props => props.theme.fontSizes.l};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

export const Heading = ({ size, children }: HeadingProps) => {
  if (size === 'sm') return <HeadingSm>{children}</HeadingSm>;
  else if (size === 'm') return <HeadingM>{children}</HeadingM>;
  else return <HeadingL>{children}</HeadingL>;
};
