import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '../index.tsx';
import { Tooltip } from '../../Tooltip.tsx';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Heading } from '../../Heading.tsx';
import { Icon } from '../../Icon.tsx';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { Spinner } from '../../Spinner.tsx';

export interface ListViewColumnDefinition {
  field: string;
  header: ReactNode;
  tooltip?: string;
  width?: string;
  sortable?: boolean;
  defaultSortAscending?: boolean; // when not defined acts like ascending
  cellNoWrap?: boolean;
  defaultValue?: ReactNode;
  fieldComponent?: (props: ListViewFieldProps) => JSX.Element;
}

interface ListViewField {
  field: string;
  columnDefinition: ListViewColumnDefinition;
  dataItem: object;
  value: ReactNode;
  component: (props: ListViewFieldProps) => JSX.Element;
}

export interface ListViewRow {
  fields: ListViewField[];
  data?: unknown;
}

export interface ListViewSorting {
  field: string;
  ascending: boolean;
}

export interface ListViewProps {
  columns: ListViewColumnDefinition[];
  rows: ListViewRow[];
  sorting?: ListViewSorting;
  allowFilters?: boolean;
  selected?: ListViewRow | ListViewRow[];
  onSortingUpdated?: (sorting?: ListViewSorting) => void;
  onItemSelected?: (row: ListViewRow) => void;
  onItemDeselected?: (row: ListViewRow) => void;
  isLoading?: boolean;
  isMultiSelect?: boolean;
}

const NoResultsStatusMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 12px;
`;

const InfoIcon = styled.div`
  padding-right: 12px;
  display: flex;
  align-items: center;
`;

const LoadingTableContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const ListViewHeading = styled.div`
  display: flex;
  align-items: center;
`;

const ListViewHeadingText = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  line-height: 1.5em;
`;

const ListViewHeadingAside = styled.div`
  flex: 0;
  margin-left: 4px;
`;

const SortingIconContainer = styled.div<{ $visible?: boolean }>`
  visibility: ${props => (props.$visible ? 'visible' : 'hidden')};
`;

export type ListViewFieldProps = {
  value: unknown;
  column: ListViewColumnDefinition;
  dataItem: object;
};

export const ListView = ({
  columns,
  rows,
  sorting,
  selected,
  onSortingUpdated,
  onItemSelected,
  onItemDeselected,
  isLoading = false,
  isMultiSelect = false
}: ListViewProps) => {
  const isSelectable = onItemSelected !== undefined && onItemSelected !== null;

  const isSelected = (row: ListViewRow) => {
    if (!selected) {
      return false;
    }

    if (isMultiSelect) {
      const selectedRows = selected as ListViewRow[];
      return selectedRows.includes(row);
    }

    return selected === row;
  };

  const toggleRowSelection = (row: ListViewRow) => {
    if (isSelected(row)) {
      onItemDeselected && onItemDeselected(row);
    } else {
      onItemSelected && onItemSelected(row);
    }
  };

  const updateSorting = (column: ListViewColumnDefinition) => {
    if (!onSortingUpdated) {
      return;
    }

    const isNewSortColumn = sorting?.field !== column.field!;

    const defaultSortIsAscending = column.defaultSortAscending ?? true;

    const shouldClearSort = !isNewSortColumn && sorting?.ascending === !defaultSortIsAscending;

    if (shouldClearSort) {
      onSortingUpdated();
      return;
    }

    onSortingUpdated({
      field: column.field!,
      ascending: isNewSortColumn ? defaultSortIsAscending : !sorting.ascending
    });
  };

  const handleColumnClick = (column: ListViewColumnDefinition) => {
    const isSortable = column.sortable ?? true;

    if (!onSortingUpdated || !isSortable || !column.field) {
      return undefined;
    }

    return () => updateSorting(column);
  };

  return (
    <>
      <Table fixedHeader={true} highlightRows={isSelectable}>
        <TableHeader>
          {columns.map(col => {
            return (
              <TableColumn key={col.field} width={col.width} onClick={handleColumnClick(col)}>
                <ListViewHeading>
                  <ListViewHeadingText>
                    {col.header}
                    {col.tooltip && (
                      <ListViewHeadingAside>
                        <Tooltip text={col.tooltip} />
                      </ListViewHeadingAside>
                    )}
                  </ListViewHeadingText>
                  <ListViewHeadingAside>
                    <SortingIconContainer $visible={sorting && sorting.field === col.field}>
                      <Icon size={'sm'} IconType={sorting?.ascending ? FaArrowUp : FaArrowDown} />
                    </SortingIconContainer>
                  </ListViewHeadingAside>
                </ListViewHeading>
              </TableColumn>
            );
          })}
        </TableHeader>
        <TableBody>
          {!isLoading ? (
            rows.map((row, index) => {
              return (
                <TableRow key={index} highlighted={isSelected(row)} onClick={() => toggleRowSelection(row)}>
                  {columns.map(col => {
                    const field = row.fields.find(x => x.field === col.field);

                    return (
                      <TableCell cellNoWrap={col.cellNoWrap} key={`${index}-${col.field}`}>
                        {field?.component &&
                          React.createElement(field.component, {
                            value: field.value,
                            column: field.columnDefinition,
                            dataItem: field.dataItem
                          })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      {isLoading && (
        <LoadingTableContainer>
          <div>
            <Spinner />
          </div>
        </LoadingTableContainer>
      )}
      {!isLoading && rows.length === 0 && (
        <NoResultsStatusMessage>
          <InfoIcon>
            <Icon size={'m'} IconType={IoIosInformationCircleOutline} />
          </InfoIcon>
          <Heading size={'m'}>No results</Heading>
        </NoResultsStatusMessage>
      )}
    </>
  );
};
